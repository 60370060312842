import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'

import { Layout, Grid, GridItem, Slider } from '../components'
import { container, padding, bgImage, bgIcon, button } from '../styles/global'
import { media } from '../styles/utils'
import { parseACF } from '../utils'
import { resolveArtist } from '../utils/shopify'

import spotify from '../assets/icons/social/spotify-inverted.svg'

const Index = (props) => {
    const queryData = useStaticQuery(query);
    const data = parseACF(queryData, 'allWordpressInfopages')
    let productsData = queryData?.allShopifyCollection?.nodes[0]?.products

    const renderSlides = (slides) => {
        // only show the first 6
        return slides.slice(0, 6).map((item, i) => {
            const image = item?.images[0]?.originalSrc
            const artist = resolveArtist(item)

            return (
                <Slide key={i} as={Link} to={`/product/${item.handle}`}>
                    <BGImage style={{backgroundColor: '#ccc'}} image={image} />
                    <Heading>{artist}</Heading>
                    <Description>{item.title}</Description>
                </Slide>
            )
        })
    }

    const renderSlider = () => {
        if (!productsData) return
        return (
            <RecentReleases>
                <Heading>Recent Releases</Heading>
                <Slider slides={productsData} renderSlides={renderSlides} />
            </RecentReleases>
        )
    }

    const renderIntro = () => {
        if (!data.description) return

        return (
            <Intro
                dangerouslySetInnerHTML={{
                    __html: data?.description,
                }}
            />
        )
    }

    const renderSocialLinks = () => {
        if (!data?.social_links) return;

        const socialMap = {
            spotify: {
                colour: '#92F754',
                label: 'Spotify'
            },
            bandcamp: {
                colour: '#57E1FF',
                label: 'Bandcamp'
            },
            youtube: {
                colour: '#E80000',
                label: 'YouTube'
            },
            instagram: {
                colour: '#A027FF',
                label: 'Instagram'
            },
        }

        const items = data.social_links.map((item, i) => {
            return (
                <ExternalLink
                    style={{ borderColor: `${socialMap?.[item?.type]?.colour}` }}
                    key={i}
                    href={item?.link}
                    target={'_blank'}
                >
                    {socialMap?.[item.type]?.label}
                </ExternalLink>
            )
        })

        return <SocialLinks>{items}</SocialLinks>
    }

    const renderProducts = () => {
        if (!productsData) return

        // only show 8 (after the first 6)
        const items = productsData.slice(6, 14).map((item, i) => {
            if (i >= 8) {
                return null
            } else {
                return <GridItem key={i} item={item} />
            }
        })

        return (
            <Products>
                <Heading>More Recent</Heading>
                <Grid items={items} />
                <ViewAll as={Link} to={`/shop`}>
                    View All
                </ViewAll>
            </Products>
        )
    }

    const renderProjects = () => {
        if (!data.bs_projects) return

        const items = data.bs_projects.map((item, i) => {
            return (
                <Item
                    key={i}
                    as={ExternalLink}
                    href={`${item.link}`}
                    target="_blank"
                    style={{ backgroundColor: `${item.background_colour}` }}
                >
                    <Image
                        key={item?.image?.sizes?.large}
                        src={item?.image?.sizes?.large}
                    >
                        {(src, loading) => {
                            return (
                                <BGIcon
                                    image={src}
                                    style={{ opacity: loading ? 0 : 1 }}
                                />
                            )
                        }}
                    </Image>
                    <Heading>{item.title}</Heading>
                </Item>
            )
        })

        return (
            <Projects>
                <Heading>BS Projects</Heading>
                <Items>{items}</Items>
            </Projects>
        )
    }

    const parseIframeHtml = (html) => {
        // remove fancy quotation marks
        const regex = /“/gi
        const regex2 = /”/gi

        let parsedHtml = html.replace(regex, '"').replace(regex2, '"')
        return parsedHtml
    }

    const renderSpotify = () => {
        if (!data.spotify_embed) return
        const parsedHtml = parseIframeHtml(data.spotify_embed)

        return (
            <Spotify>
                <Heading>
                    Now Listening on <Icon src={spotify} />
                </Heading>
                <IframeEmbed
                    width={'100%'}
                    dangerouslySetInnerHTML={{
                        __html: parsedHtml,
                    }}
                />
                <Description
                    as={ExternalLink}
                    href={'https://open.spotify.com/user/bedroomsuckrecords?si=ePIY0aVfQ1uhQtBXbePmuA'}
                    target="_blank"
                >
                    Visit our Spotify for more
                </Description>
            </Spotify>
        )
    }

    return (
        <Layout 
            meta={data && data.seo}
            noBorderSignup
        >
            <Wrapper>
                <Container>
                    {renderSlider()}
                    {renderIntro()}
                    {renderSocialLinks()}
                    {renderProducts()}
                    {renderProjects()}
                </Container>
                {renderSpotify()}
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const ExternalLink = styled.a``
const Items = styled.div``
const Item = styled.div``
const Icon = styled.img``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${(props) => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

const BGIcon = styled.div`
    background-image: url(${(props) => props.image});
    ${bgIcon};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const Container = styled.div`
    ${container}
    ${padding}
`

// Recent Releases

const RecentReleases = styled.div`
    margin-top: 36px;

    ${media.phone`
        margin-top: 33px;
    `}

    > ${Heading} {
        margin-bottom: 37px;
        text-align: center;
        font-family: 'Neue Haas', sans-serif;
        font-weight: 400;
        font-size: 24px;
        line-height: 97.7%;
        text-transform: uppercase;

        ${media.phone`
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 22px;
            text-transform: none;
        `}
    }
`

const Slide = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${BGImage} {
        height: 0;
        padding-bottom: 100%;
    }

    ${Heading} {
        margin-top: 35px;

        ${media.phone`
            margin-top: 32px;
        `}
    }

    ${Heading}, ${Description} {
        font-family: 'Neue Haas', sans-serif;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: black;

        ${media.phone`
            font-size: 18px;
            line-height: 22px;
        `}
    }
`

// Intro

const Intro = styled.div`
    margin-top: 74px;

    ${media.phone`
        margin-top: 54px;
    `}

    &,
    * {
        font-family: 'Neue Haas', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 59px;
        text-align: justify;

        ${media.phone`
            font-size: 24px;
            line-height: 29px;
        `}
    }
`

// Social Links

const SocialLinks = styled.div`
    display: flex;
    margin-top: 165px;

    ${media.phone`
        flex-flow: row wrap;
        margin-top: 48px;
    `}

    ${ExternalLink} {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 79px;
        background: white;
        border-width: 1px;
        border-style: dashed;

        font-family: Courier;
        font-size: 18px;
        line-height: 97.7%;
        color: black;

        &:not(:last-child) {
            margin-right: 9px;
            ${media.phone`
                margin-right: 5px;
            `}
        }

        ${media.phone`
            flex: none;
            width: calc(50% - 10px);
            margin: 0 5px 10px;
            height: 50px;
            font-size: 14px;
        `}
    }
`

// Products Grid

const ViewAll = styled.div``
const Products = styled.div`
    margin-top: 150px;

    ${media.phone`
        margin-top: 64px;
    `}

    > ${Heading} {
        font-family: 'Neue Haas', sans-serif;
        font-size: 86.8922px;
        line-height: 97.7%;
        text-align: center;

        ${media.phone`
            font-size: 44px;
            line-height: 97.7%;
        `}
    }

    .grid-items {
        margin-top: 48px;
        margin-bottom: 53px;
        grid-row-gap: 81px;

        ${media.phone`
            margin-top: 20px;
            margin-bottom: 32px;
            grid-row-gap: 20px;
        `}
    }

    ${ViewAll} {
        ${button};
        background-color: white;
        color: black;
        border: 1px dashed black;
    }
`

// BS Projects

const Projects = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 93px;

    > ${Heading} {
        font-family: 'Neue Haas', sans-serif;
        font-size: 86.8922px;
        line-height: 97.7%;
        text-align: center;

        ${media.phone`
            font-size: 44px;
            line-height: 97.7%;
        `}
    }

    ${Items} {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        width: 1000px;
        max-width: 100%;
        margin-top: 42px;
        margin-bottom: -30px;
        margin-left: -12px;
        margin-right: -12px;
    }

    ${Item} {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 440px;
        height: 472px;
        margin: 0 12px 30px;
        padding: 29px;
        border: 1px dashed black;

        ${media.phone`
            width: calc(50% - 10px);
            height: 185px;
            margin: 0 5px 12px;
            padding: 20px;

            justify-content: center;
        `}

        ${BGIcon} {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 180px;
            max-width: 100%;
            height: 160px;
            mix-blend-mode: multiply;

            ${media.phone`
                position: static;
                transform: none;
                width: 80px;
                height: 80px;
                margin-top: 15px;
            `}
        }

        ${Heading} {
            margin-top: auto;
            font-family: Courier;
            font-weight: 400;
            font-size: 18px;
            line-height: 97.7%;
            color: black;

            ${media.phone`
                margin-top: auto;
                font-size: 14px;
                line-height: 97.7%;
                text-align: center;
            `}
        }
        
    }
`

// Spotify

const IframeEmbed = styled.div`
    width: 100%;

    iframe {
        width: 100%;
    }
`

const Spotify = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 220px;
    ${padding}
    padding-top: 42px;
    padding-bottom: 52px;
    background: #40AD48;

    ${media.phone`
        margin-top: 88px;
        padding-top: 28px;
        padding-bottom: 38px;
    `}

    ${Heading} {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Neue Haas', sans-serif;
        font-weight: 400;
        font-size: 24px;
        line-height: 97.7%;
        color: #ffffff;

        ${media.phone`
            font-size: 18px;
        `}

        ${Icon} {
            margin-left: 22px;

            ${media.phone`
                margin-left: 15px;
            `}
        }
    }

    ${IframeEmbed} {
        margin-top: 54px;
        max-width: 918px;

        ${media.phone`
            margin-top: 31px;
        `}
    }

    ${Description} {
        margin-top: 59px;
        font-family: 'Neue Haas', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        color: white;

        ${media.phone`
            margin-top: 35px;
            font-size: 14px;
        `}
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }

        allShopifyCollection(limit: 16, filter: {handle: {eq: "bedroom-suck-records"}}) {
            nodes {
                title
                handle
                products {
                    handle
                    title
                    tags
                    productType
                    images {
                        originalSrc
                    }
                    priceRange {
                        minVariantPrice {
                            amount
                            currencyCode
                        }
                    }
                }
            }
        }
    }
`

export default Index
